import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container"]
  
  connect() {
    this.observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        const width = entry.contentRect.width
        if (width < 300) {
          this.containerTarget.classList.add("tw-flex-col")
        } else {
          this.containerTarget.classList.remove("tw-flex-col")
        }
      }
    })

    this.observer.observe(this.element)
  }

  disconnect() {
    this.observer.disconnect()
  }
} 