import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["toggleButton", "hiddenContent"]

  toggle() {
    this.hiddenContentTarget.classList.toggle('hidden');

    if (this.hiddenContentTarget.classList.contains('hidden')) {
      this.toggleButtonTarget.innerText = 'Show';
    } else {
      this.toggleButtonTarget.innerText = 'Hide';
    }
  }
}
