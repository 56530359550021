import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "popup", "button"]

  onPostSuccess(event) {
    const [data, status, xhr] = event.detail;
    this.popupTarget.insertAdjacentHTML("beforeend", xhr.response);
    
    $("#kt_chat_modal").show();
    $("#placeholder-modal-spinner").html("");
    this.toggleButtonState();
  }

  onPostSuccessDashboard(event) {
    const [data, status, xhr] = event.detail;
    
    $(`#conversationPopup`).html("");
    $(`#conversationPopup`)[0].insertAdjacentHTML("beforeend", xhr.response);
    
    $("#kt_chat_modal").show();
    $("#placeholder-modal-spinner").html("");
    this.toggleButtonState();
  }

  submitFormDashboard(event) {
    let participant = event.target.dataset.participant;
    Rails.fire($(`#conversation-form-${participant}`)[0], "submit");
    
    $("#placeholder-modal-spinner").html(spinner());
    this.toggleButtonState();
  }

  submitForm(event) {
    event.preventDefault();
    Rails.fire(this.formTarget, "submit");
    
    $("#placeholder-modal-spinner").html(spinner());
    this.toggleButtonState();
  }
  
  toggleButtonState() {
    this.buttonTarget.disabled = !this.buttonTarget.disabled;
  }
}
