/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <e%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

// Do not include Sentry.js  -> && !window.location.host.includes("staging")
if (!window.location.host.includes("localhost") ) {
  Sentry.init({
    // TODO(dtredger) this value is duplicated in config/initializers/sentry.rb
    dsn: "https://627ed1da3b4a4ff697689030cff130c2@sentry.io/1758501",
    integrations: [ new Integrations.BrowserTracing(), ],
    sampleRate: 0.1,
    environment: "production",
    tracesSampleRate: 0.0001,
  })
  window.Sentry = Sentry;
}

import "stimulus"
import "controllers"
import "components"
import "actiontext"
import "activestorage"

import ApexCharts from "apexcharts";
window.ApexCharts = ApexCharts;

import SignaturePad from "signature_pad";
window.SignaturePad = SignaturePad;

import Croppie from "croppie";
window.Croppie = Croppie;

import { Twilio } from "@twilio/conversations/dist/twilio-conversations";
window.Twilio = Twilio;

import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;

import Papa from "papaparse";
window.Papa = Papa;

// Theme files and local libs
import "../lib/kt-theme-bundle";

import { orderMenu } from "../lib/kt-layout-order-menu"
window.OrderMenu = orderMenu;

import { chat } from "../lib/chat.min";
window.KTAppChat = chat;

import { wrapDefaultInput } from "../lib/default-file-input";
window.wrapDefaultInput = wrapDefaultInput;

// Actions required to be initialized after page load.
jQuery(document).ready(function() {
  KTAppChat.init();
  wrapDefaultInput(jQuery);
  OrderMenu.init('kt_order_menu');
  KTApp.init();
});
