import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    // Use javascripts/chat.js from theme to scroll messages inside window
    window.KTAppChat.init();
  }

  connect() {
    // init ktapp on popup connect
    window.KTAppChat.init();
    window.chatPopupOpen = this.element.getAttribute("data-conversation-id");

    document.onkeydown = function (evt) {
      if (evt.keyCode === 27) {
        // Escape key pressed
        $('#kt_chat_modal').hide();
      }
    };
  }

  disconnect() {
    window.chatPopupOpen = false
  }

  // TODO(dtredger): Don't hardcode ID to close'
  closePopup() {
    window.chatPopupOpen = false
    $('#kt_chat_modal').hide();
  }
}
