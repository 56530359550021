import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["trigger", "content", "arrow"]

  connect() {
    this.updateArrowPosition()
  }

  show() {
    const content = this.contentTarget
    const trigger = this.triggerTarget
    const arrow = this.arrowTarget

    // Position the tooltip
    const triggerRect = trigger.getBoundingClientRect()
    const contentRect = content.getBoundingClientRect()

    // Center horizontally
    content.style.left = `${(triggerRect.width - contentRect.width) / 2}px`
    
    // Position above the trigger
    content.style.bottom = `${triggerRect.height + 10}px`

    // Position arrow
    arrow.style.left = `${contentRect.width / 2 - 6}px`
    arrow.style.bottom = '-6px'

    // Show tooltip with animation
    content.classList.remove("tw-invisible", "tw-opacity-0", "tw-scale-95", "tw-translate-y-2")
    content.classList.add("tw-visible", "tw-opacity-100", "tw-scale-100", "tw-translate-y-0")
  }

  hide() {
    const content = this.contentTarget
    content.classList.remove("tw-visible", "tw-opacity-100", "tw-scale-100", "tw-translate-y-0")
    content.classList.add("tw-invisible", "tw-opacity-0", "tw-scale-95", "tw-translate-y-2")
  }

  updateArrowPosition() {
    const arrow = this.arrowTarget
    const content = this.contentTarget
    const contentRect = content.getBoundingClientRect()
    arrow.style.left = `${contentRect.width / 2 - 6}px`
  }
} 