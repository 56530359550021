import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "orders", "userId", "customerId", "user", 'inviteTypeField',
    'approvalMessage', 'areYouSure', 'textArea', 'yesButton', 'errorMessage'
  ]

  connect() {
    console.log("Hire is now connected");
    this.initialize();
    
    // Listen for modal show event
    $('#hireOrderInviteModal').on('show.bs.modal', (event) => {
      const link = $(event.relatedTarget);
      const userId = link.data('userid');
      const customerId = link.data('customerid');
      
      if (userId && customerId) {
        // Set values on the form
        $(this.userTarget).val(userId);
        
        // Load projects
        this.loadProjects(userId, customerId);
      }
      
      // Hide any error message when modal is shown
      if (this.hasErrorMessageTarget) {
        this.errorMessageTarget.classList.add('tw-hidden');
      }
    });
  }

  initialize() {
    // Get the approval messages from the first radio button
    if (this.inviteTypeFieldTarget) {
      try {
        this.inviteTypeApprovalMessages = JSON.parse(this.inviteTypeFieldTarget.dataset.inviteTypeApprovalMessages);
      } catch (e) {
        this.inviteTypeApprovalMessages = {};
      }
    }
  }

  retrieveOrdersForUser(event) {
    // Get user ID either from event target or from invite type field
    let userId = event.target.dataset.userid || this.inviteTypeFieldTarget?.dataset.userid;
    let customerId = event.target.dataset.customerid || this.inviteTypeFieldTarget?.dataset.customerid;
    
    // If we don't have a user ID, return early
    if (!userId || !customerId) {
      console.error('Missing user ID or customer ID');
      return;
    }

    // Hide any error message when a field changes
    if (this.hasErrorMessageTarget) {
      this.errorMessageTarget.classList.add('tw-hidden');
    }

    // Update the invite type description when the invite type changes
    this.updateInviteTypeDescription();

    this.loadProjects(userId, customerId);
  }

  addOrderOption(value, text, disable) {
    let option;
    if (!disable) {
      option = "<option value=" + value + ">" + text + "</option>";
    } else {
      // Add multiple classes and inline style to ensure the disabled styling works
      option = "<option class='disabled-option' style='color: #c7c7c7 !important; font-style: italic !important;' value=" + value + " disabled>" + text + "</option>";
    }
    $(this.ordersTarget).append(option);
  }

  addEmptyOption() {
    let option = "<option value=''>Please choose an option</option>";
    $(this.ordersTarget).append(option);
  }

  // Check if all required fields are filled
  checkFormValidity() {
    const inviteType = document.querySelector('input[name="hire_order_invite[invite_type]"]:checked');
    const orderId = this.ordersTarget.value;
    const message = this.textAreaTarget.value;
    
    return {
      isValid: !!(inviteType && orderId && message),
      inviteType,
      orderId,
      message
    };
  }

  // Set the approval message in the confirmation modal based on the selected invite type
  updateInviteTypeDescription() {
    const { inviteType } = this.checkFormValidity();
    const selectedValue = inviteType ? inviteType.value : '';
    
    // Set the approval message based on the selected invite type
    if (selectedValue && this.inviteTypeApprovalMessages) {
      // Use the selected value as the key to get the appropriate message
      const message = this.inviteTypeApprovalMessages[selectedValue];
      
      if (message) {
        this.approvalMessageTarget.innerText = message;
      } else {
        this.approvalMessageTarget.innerText = "Are you sure you want to invite this freelancer?";
      }
      
      this.areYouSureTarget.innerText = 'Are you sure you want to continue?';
      this.yesButtonTarget.disabled = false;
    }
  }

  validateAndShowConfirmation(event) {
    // Check if all required fields are filled
    const { isValid, inviteType, orderId, message } = this.checkFormValidity();
    
    // Hide any previous error message
    if (this.hasErrorMessageTarget) {
      this.errorMessageTarget.classList.add('tw-hidden');
    }
    
    // Only show the confirmation modal if all fields are filled
    if (isValid) {
      // Update the confirmation message
      this.updateInviteTypeDescription();
      
      // Show the confirmation modal
      $('#hireOrderInviteConfirmModal').modal('show');
    } else {
      // Show error message
      this.errorMessageTarget.textContent = 'Please fill out all required fields before proceeding.';
      this.errorMessageTarget.classList.remove('tw-hidden');
      
      // Focus on the first empty required field
      if (!inviteType) {
        // Can't directly focus a radio button group, so we'll scroll to it
        document.querySelector('.radio-card').scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else if (!orderId) {
        this.ordersTarget.focus();
      } else if (!message) {
        this.textAreaTarget.focus();
      }
    }
  }

  loadProjects(userId, customerId) {
    let inviteType = this.inviteTypeFieldTarget?.value;
    let that = this;

    // Clear current options
    $(this.ordersTarget).empty();

    setupAjax();
    $.ajax({
      url: "/hire_order_invites/available_orders",
      method: "POST",
      data: {
        hire_order_invite: {
          user_id: userId,
          customer_id: customerId,
          invite_type: inviteType
        }
      },
      dataType: "json",
      success: function (data) {
        var orders = JSON.parse(data.orders);
        that.addEmptyOption();

        if (orders.length > 0) {
          orders.forEach(function (order) {
            that.addOrderOption(order[1], order[0], order[2]);
          });
        }
      }
    });
  }
}

